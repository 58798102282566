<template>
  <div class="container home3">
    <div class="row">
      <maintop1 ref="newmesstop" />
      <maintop2 />
      <div
        :class="
          flag_nav ? 'main_fix_offset col-xl-10' : 'main_fix_width col-xl-12'
        "
        class="mt-2"
      >
        <!-- <img src="@/assets/img/sign_up/gift-btn.png" /> -->
        <img :src="logo" style="width: 180px; height: 60px" />
        <div>
          <div
            class="d-xl-flex d-blockx mb-3 subs_card p-2 rounded-4"
            @click="click_card(1)"
            :class="choice_li.includes(1) ? 'subs_card_hover' : ''"
          >
            <img
              src="@/assets/img/left/bt_extrabonus.png"
              class="rounded-4 card_img"
            />
            <div Class="d-flex card_detail" style="flex: 2 1">
              <div style="flex: 2 1" class="px-2">
                <p class="mb-1">顯示卡別名稱</p>
                <p>內容</p>
              </div>
              <div class="d-flex flex-column justify-content-center p-2">
                <p v-if="false">{{ $t("subs_text2") }}：2023/12/31</p>
                <p>{{ $t("cur") }}：金額</p>
              </div>
            </div>
          </div>

          <div
            class="d-xl-flex d-blockx mb-3 subs_card p-2 rounded-4"
            @click="click_card(2)"
            :class="choice_li.includes(2) ? 'subs_card_hover' : ''"
          >
            <img
              src="@/assets/img/left/bt_extrabonus.png"
              class="rounded-4 card_img"
            />
            <div Class="d-flex card_detail" style="flex: 2 1">
              <div style="flex: 2 1" class="px-2">
                <p class="mb-1">顯示卡別名稱2</p>
                <p>內容</p>
              </div>
              <div class="d-flex flex-column justify-content-center p-2">
                <p v-if="false">{{ $t("subs_text2") }}：2023/12/31</p>
                <p>{{ $t("cur") }}：金額</p>
              </div>
            </div>
          </div>
          <div
            class="d-xl-flex d-blockx mb-3 subs_card p-2 rounded-4 subs_card_done"
            @click="click_card(3)"
            :class="choice_li.includes(3) ? 'subs_card_hover' : ''"
          >
            <img
              src="@/assets/img/left/bt_extrabonus.png"
              class="rounded-4 card_img"
            />
            <div Class="d-flex card_detail" style="flex: 2 1">
              <div style="flex: 2 1" class="px-2">
                <p class="mb-1">顯示卡別名稱3</p>
                <p>內容</p>
              </div>
              <div class="d-flex flex-column justify-content-center p-2">
                <p>{{ $t("subs_text2") }}：2023/12/31</p>
                <p>{{ $t("cur") }}：金額</p>
              </div>
            </div>
          </div>
        </div>

        <div class="w-25 mx-auto text-center w-lg-75 my-5">
          <button class="border-0 text-white bg_green_btn p-2 w-100">
            <a>{{ $t("subs_text1").toUpperCase() }}</a>
          </button>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>
<script>
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "slot",
  data() {
    return {
      choice_li: [],
    };
  },
  computed: {
    ...mapGetters(["flag_nav"]),
    ...mapGetters(["logo"]),
  },
  components: {
    Footer,
    maintop1,
    maintop2,
  },
  watch: {},
  created() {
    this.getLogo();
  },
  mounted() {},
  methods: {
    ...mapActions(["getLogo"]),
    click_card: function (index) {
      if (this.choice_li.indexOf(index) !== -1) {
        this.choice_li.splice(this.choice_li.indexOf(index), 1);
      } else this.choice_li.push(index);
    },
  },
};
</script>
